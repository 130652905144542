import GoogleButton from "react-google-button";
import { baseAddress } from "../hooks/useApi";
import { Box } from "@chakra-ui/react";

export const GoogleLoginButton = () => {
  const login = () => {
    const googleLoginUrl = `${baseAddress}/login`;
    window.location.href = `${googleLoginUrl}`;
  };

  return (
    <Box marginTop="50px">
      <GoogleButton onClick={login} />
    </Box>
  );
};
