import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  useToast,
  Text,
  VStack,
  FormErrorMessage,
  Spinner,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useContacts } from "../contexts/ContactsContext";
import { AddContactRequest } from "../types/Contact";
import { Field, FormikProvider, useFormik } from "formik";
import { useState } from "react";

export const AddContact = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { addContact } = useContacts();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      contactNote: "",
      isCompany: false,
    } as AddContactRequest,
    onSubmit: async (request) => {
      setIsLoading(true);
      await addContact(request);
      setIsLoading(false);
      formik.resetForm();
      toast({
        title: "Zapisano klienta.",
        description: `${request.name} (${request.phoneNumber})`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
    },
  });

  return (
    <>
      <Button colorScheme="blue" width="100%" onClick={onOpen}>
        Dodaj nowy kontakt
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blueAlpha.300" backdropFilter="blur(10px) hue-rotate(10deg)" />
        <ModalContent minWidth="600px">
          <Box padding="16px">
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Text>Dodaj nowego klienta</Text>
                <VStack margin={4}>
                  <HStack width="100%">
                    <FormControl
                      minHeight="100px"
                      width="50%"
                      isRequired
                      isInvalid={
                        formik.errors.name !== undefined && formik.errors.name.length > 0 && formik.touched.name
                      }
                    >
                      <FormLabel htmlFor="name">Nazwa klienta</FormLabel>
                      <Field
                        as={Input}
                        id="name"
                        name="name"
                        variant="filled"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        validate={(value: string) => (value.length >= 3 ? "" : "Nazwa musi mieć minimum 3 znaki")}
                      />
                      <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      minHeight="100px"
                      width="50%"
                      isRequired
                      isInvalid={
                        formik.errors.phoneNumber !== undefined &&
                        formik.errors.phoneNumber.length > 0 &&
                        formik.touched.phoneNumber
                      }
                    >
                      <FormLabel htmlFor="phoneNumber">Numer telefonu</FormLabel>
                      <Field
                        as={Input}
                        id="phoneNumber"
                        name="phoneNumber"
                        variant="filled"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}
                        validate={(value: string) => {
                          const match = value.match(/\d/g);
                          if (match === undefined || match === null) {
                            return "Numer musi zawierać cyfry";
                          }
                          return match.length >= 9 ? "" : "Numer telefonu to minimum 9 cyfr";
                        }}
                      />
                      <FormErrorMessage>{formik.errors.phoneNumber}</FormErrorMessage>
                    </FormControl>
                  </HStack>
                  <HStack width="100%" hidden={formik.values.name.length < 3 || formik.values.phoneNumber.length < 9}>
                    <FormControl width="50%">
                      <FormLabel htmlFor="firstName">Imię</FormLabel>
                      <Field
                        as={Input}
                        id="firstName"
                        name="firstName"
                        variant="filled"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                      />
                    </FormControl>
                    <FormControl width="50%">
                      <FormLabel htmlFor="lastName">Nazwisko</FormLabel>
                      <Field
                        as={Input}
                        id="lastName"
                        name="lastName"
                        variant="filled"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                      />
                    </FormControl>
                  </HStack>
                  {isLoading ? (
                    <Spinner size="xl" />
                  ) : (
                    <Button colorScheme="teal" width="100%" type="submit" marginTop="20px">
                      Dodaj
                    </Button>
                  )}
                </VStack>
              </form>
            </FormikProvider>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
