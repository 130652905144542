import { FC, createContext, useContext, useState } from "react";
import { AddContactRequest, BaseContactDto, DeleteContactRequest } from "../types/Contact";
import { useApi } from "../hooks/useApi";
import { useAuth } from "./AuthContext";

export interface ContactsContextType {
  contacts: BaseContactDto[];
  refresh: () => Promise<void>;
  addContact: (request: AddContactRequest) => Promise<void>;
  removeContact: (request: DeleteContactRequest) => Promise<void>;
  isLoading: boolean;
}

export const ContactsContext = createContext<ContactsContextType>(null!);

export const ContactsProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useAuth();
  const api = useApi();
  const [contacts, setContacts] = useState<BaseContactDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const refresh = async (): Promise<void> => {
    if (!auth.isLoggedIn) {
      return;
    }
    setIsLoading(true);
    const response = await api.searchContacts();
    setContacts(response.contacts);
    setIsLoading(false);
  };

  const addContact = async (request: AddContactRequest): Promise<void> => {
    if (!auth.isLoggedIn) {
      return;
    }
    const contact = await api.addContact(request);
    setContacts([...contacts, contact]);
  };

  const removeContact = async (request: DeleteContactRequest): Promise<void> => {
    if (!auth.isLoggedIn) {
      return;
    }
    await api.deleteContact(request);
    setContacts(contacts.filter((x) => x.id !== request.contactId));
  };

  const value: ContactsContextType = {
    contacts,
    refresh,
    addContact,
    removeContact,
    isLoading,
  };
  return <ContactsContext.Provider value={value}>{children}</ContactsContext.Provider>;
};

export const useContacts = () => useContext(ContactsContext);
