import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  IconButton,
  useToast,
  Text,
  Spinner,
  HStack,
  Box,
} from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { useApi } from "../hooks/useApi";
import { useContacts } from "../contexts/ContactsContext";
import { DeleteIcon, PhoneIcon } from "@chakra-ui/icons";
import { BaseContactDto, DeleteContactRequest } from "../types/Contact";
import { useSignalR } from "../contexts/SignalRContext";

export const ContactsTable = () => {
  const { contacts, refresh, isLoading } = useContacts();
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current === true) {
      return;
    }
    mounted.current = true;
    refresh();
  }, [refresh]);

  if (isLoading) return <Spinner size="xl" />;

  if (contacts === undefined || contacts.length === 0) return <></>;
  return (
    <TableContainer>
      <Table variant={"simple"} colorScheme="teal">
        <Thead>
          <Tr>
            <Th>Nazwa klienta</Th>
            <Th>Numer telefonu</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {contacts.map((x) => (
            <ContactTableLine key={x.id} contact={x} />
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Nazwa klienta</Th>
            <Th>Numer telefonu</Th>
            <Th></Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export const ContactTableLine: FC<{ contact: BaseContactDto }> = ({ contact }) => {
  const api = useApi();
  const toast = useToast();
  const { removeContact } = useContacts();
  const call = () => api.callPhone(contact.phoneNumber);
  const signalR = useSignalR();
  const [mouseOnCall, setMouseOnCall] = useState(false);
  const [mouseOnDelete, setMouseOnDelete] = useState(false);

  const remove = async () => {
    const request: DeleteContactRequest = { contactId: contact.id };
    await removeContact(request);
    toast({
      title: `Usunięto klienta ${contact.name} ${contact.phoneNumber} z listy.`,
      description: "Lista jest trzymana lokalnie, na urządzeniu użytkownika.",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Tr>
      <Td>
        <Text
          as={mouseOnCall || mouseOnDelete ? "u" : undefined}
          color={mouseOnCall ? "teal.400" : mouseOnDelete ? "red.400" : undefined}
        >
          {contact.name}
        </Text>
        <HStack>
          <Text
            fontSize="xs"
            as={mouseOnCall || mouseOnDelete ? "u" : undefined}
            color={mouseOnCall ? "teal.400" : mouseOnDelete ? "red.400" : undefined}
          >
            {contact.firstName}
          </Text>
          <Text
            fontSize="xs"
            as={mouseOnCall || mouseOnDelete ? "u" : undefined}
            color={mouseOnCall ? "teal.400" : mouseOnDelete ? "red.400" : undefined}
          >
            {contact.lastName}
          </Text>
        </HStack>
      </Td>
      <Td>
        <Text
          as={mouseOnCall || mouseOnDelete ? "u" : undefined}
          color={mouseOnCall ? "teal.400" : mouseOnDelete ? "red.400" : undefined}
        >
          {contact.phoneNumber}
        </Text>
      </Td>
      <Td>
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            variant="outline"
            colorScheme="teal"
            onClick={call}
            icon={<PhoneIcon />}
            aria-label={"Call"}
            size="lg"
            isDisabled={!signalR.phoneConnected}
            onMouseEnter={() => setMouseOnCall(signalR.phoneConnected)}
            onMouseLeave={() => setMouseOnCall(false)}
            marginRight="16px"
          >
            Zadzwoń
          </IconButton>
          <IconButton
            variant="outline"
            color="tomato"
            onClick={remove}
            icon={<DeleteIcon />}
            aria-label={"Delete"}
            size="lg"
            onMouseEnter={() => setMouseOnDelete(true)}
            onMouseLeave={() => setMouseOnDelete(false)}
          >
            Usuń
          </IconButton>
        </Box>
      </Td>
    </Tr>
  );
};
