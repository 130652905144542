import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  NumberInput,
  NumberInputField,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContacts } from "../contexts/ContactsContext";
import { useEffect, useRef, useState } from "react";
import { useSignalR } from "../contexts/SignalRContext";
import { BaseContactDto } from "../types/Contact";
import { useApi } from "../hooks/useApi";

export const Calling = () => {
  const { contacts, refresh } = useContacts();
  const [sessionContacts, setSessionContacts] = useState<BaseContactDto[]>();
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current === true) {
      return;
    }
    mounted.current = true;
    refresh();
  }, [refresh]);

  useEffect(() => {
    setSessionContacts(contacts);
  }, [contacts, sessionContacts]);

  const { callPhone } = useApi();
  const { phoneConnected } = useSignalR();
  const [callingStarted, setCallingStarted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [callingSecondsDistance, setCallingSecondsDistance] = useState<number>(20);
  const [isCallOngoing, setIsCallOngoing] = useState(false);
  const [secondsToNextCall, setSecondsToNextCall] = useState(0);
  const [isCountdown, setIsCountdown] = useState(false);

  useEffect(() => {
    if (secondsToNextCall === 0) {
      setIsCountdown(false);
      return;
    } else {
      setIsCountdown(true);
    }
    const timer = setInterval(() => {
      if (phoneConnected) setSecondsToNextCall(secondsToNextCall - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [phoneConnected, secondsToNextCall]);

  const startIteration = async () => {
    if (phoneConnected !== true) {
      return;
    }
    next();
  };

  useEffect(() => {
    if (secondsToNextCall === 0 && isCountdown === false && currentIndex !== -1) {
      next();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountdown]);

  const next = async () => {
    const index = currentIndex + 1;
    if (phoneConnected !== true || isCallOngoing === true) {
      return;
    }
    if (sessionContacts === undefined || sessionContacts.length < index) {
      return;
    }
    if (secondsToNextCall > 0) {
      return;
    }
    const contact = sessionContacts[index];
    try {
      await callPhone(contact.phoneNumber);
      setCurrentIndex(index);
      setCallingStarted(true);
    } catch {
      return;
    }
  };

  useEffect(() => {
    if (isCallOngoing === false && phoneConnected === false && callingStarted === true) {
      setIsCallOngoing(true);
    }
  }, [callingStarted, isCallOngoing, phoneConnected]);

  useEffect(() => {
    if (isCallOngoing === true && phoneConnected === true) {
      setIsCallOngoing(false);
      setCallingStarted(false);
      setSecondsToNextCall(callingSecondsDistance);
      setIsCountdown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallOngoing, phoneConnected]);

  if (sessionContacts === undefined) return <Spinner size="lg" />;

  return (
    <VStack display="flex" justifyContent="center">
      <VStack width="500px">
        {phoneConnected ? (
          <Badge colorScheme="green">Połączono z telefonem</Badge>
        ) : (
          <Badge colorScheme="red">Nie połączono z telefonem</Badge>
        )}
        {callingStarted ? (
          <Badge colorScheme="green">Rozpoczęto połączenie</Badge>
        ) : (
          <Badge colorScheme="red">Nie rozpoczęto połączenia</Badge>
        )}
        {isCallOngoing ? (
          <Badge colorScheme="green">Podczas połączenia</Badge>
        ) : (
          <Badge colorScheme="red">Nie jest podczas połączenia</Badge>
        )}
        {isCountdown === false && isCallOngoing === false && callingStarted === false && (
          <>
            <Text>Odstęp między połączeniami w sekundach: {callingSecondsDistance}</Text>
            <NumberInput>
              <NumberInputField
                value={callingSecondsDistance}
                onChange={(x) => {
                  const parsed = parseInt(x.target.value);
                  if (parsed > 0) {
                    setCallingSecondsDistance(parsed);
                  } else {
                    setCallingSecondsDistance(0);
                  }
                }}
              />
            </NumberInput>
            <Button isDisabled={!phoneConnected} onClick={startIteration}>
              Zacznij dzwonić
            </Button>
          </>
        )}

        {isCountdown && <Text fontSize="3xl">Pozostało {secondsToNextCall}s</Text>}
      </VStack>
      <HStack wrap="wrap" padding="16px">
        {sessionContacts.map((x, i) => (
          <Box marginTop="8px" marginBottom="8px" marginLeft="8px" marginRight="8px" width="350px" height="150px">
            <Card
              borderWidth="2px"
              borderColor={
                currentIndex === i ? "yellow" : currentIndex === i - 1 ? "orange" : currentIndex > i ? "white" : "black"
              }
            >
              <CardBody>
                <HStack>
                  <Text>
                    {i + 1}. {x.phoneNumber}
                  </Text>
                </HStack>
                <Text>
                  {x.name} {` (${x.firstName} ${x.lastName})`}
                </Text>
                <Button
                  color="tomato"
                  width="100%"
                  marginTop="8px"
                  onClick={() => setSessionContacts(sessionContacts.filter((c) => c.id !== x.id))}
                >
                  Usuń z sesji {"(nie usunie kontaktu)"}
                </Button>
              </CardBody>
            </Card>
          </Box>
        ))}
      </HStack>
    </VStack>
  );
};
