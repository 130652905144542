import { createContext, useContext, useEffect, useState } from "react";

export interface AuthContextType {
  isLoggedIn: boolean;
  setLoggedIn: () => void;
  setLoggedOut: () => void;
}

export const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const localLoggedIn = localStorage.getItem("loggedIn")?.toLocaleLowerCase() === "true";
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(localLoggedIn);

  useEffect(() => {
    localStorage.setItem("loggedIn", JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  const setLoggedIn = () => {
    setIsLoggedIn(true);
  };

  const setLoggedOut = () => {
    setIsLoggedIn(false);
  };

  const value: AuthContextType = {
    isLoggedIn: isLoggedIn,
    setLoggedIn,
    setLoggedOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
