import { Box, Button, HStack } from "@chakra-ui/react";
import { useApi } from "../hooks/useApi";
import { ConnectPhoneButton } from "./ConnectPhoneButton";
import { MdArrowDownward, MdContactPhone, MdInput, MdPhone, MdSettings } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const Menu = () => {
  const api = useApi();
  const navigate = useNavigate();
  return (
    <Box>
      <HStack justifyContent="center" padding="8px" spacing="24px">
        <Button rightIcon={<MdContactPhone />} colorScheme="blue" variant="outline" onClick={() => navigate("/")}>
          Lista kontaktów
        </Button>
        <Button
          rightIcon={<MdArrowDownward />}
          colorScheme="blue"
          variant="outline"
          onClick={() => navigate("/import")}
        >
          Importuj kontakty
        </Button>
        <Button rightIcon={<MdPhone />} colorScheme="blue" variant="outline" onClick={() => navigate("/calling")}>
          Dzwonienie
        </Button>
        <ConnectPhoneButton />
        <Button rightIcon={<MdSettings />} colorScheme="blue" variant="outline" isDisabled>
          Ustawienia
        </Button>
        <Button rightIcon={<MdInput />} colorScheme="red" variant="outline" onClick={api.logout}>
          Wyloguj
        </Button>
      </HStack>
    </Box>
  );
};
