import { VStack, Box, Badge } from "@chakra-ui/react";
import { AddContact } from "../components/AddContact";
import { ContactsTable } from "../components/ContactsTable";
import { useSignalR } from "../contexts/SignalRContext";

export const Contacts = () => {
  const signalR = useSignalR();
  return (
    <Box>
      <VStack spacing={4}>
        {signalR.phoneConnected ? (
          <Badge colorScheme="green">Połączono z telefonem</Badge>
        ) : (
          <Badge colorScheme="red">Nie połączono z telefonem</Badge>
        )}
        <Box>
          <AddContact />
        </Box>
        <Box>
          <ContactsTable />
        </Box>
      </VStack>
    </Box>
  );
};
