import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Select,
  Tbody,
  Td,
  Box,
  Button,
  AlertIcon,
  Alert,
} from "@chakra-ui/react";
import { FC, useState } from "react";

export const SetupContactsTable: FC<{
  separatedTable: string[][];
  recordFieldsCount: number;
  next: (columnSettings: ("" | "firstname" | "lastname" | "name" | "phoneNumber")[]) => void;
}> = ({ separatedTable, recordFieldsCount, next }) => {
  const [columnSettings, setColumnSettings] = useState<("" | "firstname" | "lastname" | "name" | "phoneNumber")[]>([]);
  const [error, setError] = useState<string | undefined>('Pola "Nazwa" i "Numer telefonu" muszą być ustawione');

  const handleNext = () => {
    next(columnSettings);
  };
  return (
    <TableContainer>
      {error !== undefined && (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      )}
      <Table variant="simple">
        <Thead>
          <Tr>
            {separatedTable !== undefined &&
              separatedTable.length > 0 &&
              recordFieldsCount >= 2 &&
              Array.from(Array(recordFieldsCount).keys()).map((x) => (
                <Th key={x}>
                  <Box>
                    <Select
                      placeholder="Wybierz"
                      defaultValue={""}
                      onChange={(changed) => {
                        const value = changed.target.value as "" | "firstname" | "lastname" | "name" | "phoneNumber";
                        const arr = columnSettings;
                        arr[x] = value;
                        setColumnSettings(arr);
                        if (arr.indexOf("name") === -1 || arr.indexOf("phoneNumber") === -1) {
                          setError('Pola "Nazwa" i "Numer telefonu" muszą być ustawione');
                          return;
                        }
                        setError(undefined);
                      }}
                    >
                      <option value="firstname">Imię</option>
                      <option value="lastname">Nazwisko</option>
                      <option value="name">Nazwa</option>
                      <option value="phoneNumber">Numer telefonu</option>
                    </Select>
                  </Box>
                </Th>
              ))}
          </Tr>
        </Thead>
        <Tbody>
          {separatedTable.map((tableLine, i) => (
            <Tr key={i}>
              {tableLine.map((x, i) => (
                <Td key={i}>{x}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Button
        marginTop="20px"
        marginBottom="20px"
        width="100%"
        colorScheme="teal"
        onClick={handleNext}
        isDisabled={error !== undefined && error.length > 0}
      >
        Dalej
      </Button>
    </TableContainer>
  );
};
