import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import { useApi } from "../hooks/useApi";
import { MdPhone } from "react-icons/md";

export const ConnectPhoneButton = () => {
  const [code, setCode] = useState<string>();
  const api = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const open = async () => {
    setCode(undefined);
    onOpen();
    const result = await api.getMobilePairCode();
    setCode(result);
  };
  const close = () => {
    onClose();
  };

  return (
    <>
      <Button rightIcon={<MdPhone />} colorScheme="blue" variant="outline" onClick={open}>
        Połącz z telefonem
      </Button>

      <Modal isOpen={isOpen} onClose={close}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Połącz z telefonem</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={8}>
            {code === undefined ? (
              <Box display="flex" justifyContent="center">
                <Spinner size="xl" />
              </Box>
            ) : (
              <Text fontSize="5xl" letterSpacing={16} textAlign="center">
                {code}
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button width="100%" marginBottom="8px" colorScheme="teal" onClick={close}>
              Połączyłem się już z telefonem
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
