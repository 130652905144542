import {
  useSteps,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepDescription,
  StepSeparator,
  Button,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import { GenerateAddContactsRequest } from "../components/Importing/GenerateAddContactsRequest";
import { RawTableContactsParser } from "../components/Importing/RawTableContactsParser";
import { SendContactsToServer } from "../components/Importing/SendContactsToServer";
import { SetupContactsTable } from "../components/Importing/SetupContactsTable";
import { AddContactRequest } from "../types/Contact";

export const ImportContacts = () => {
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 4,
  });
  const [rawTable, setRawTable] = useState<string>("");
  const [separatedTable, setSeparatedTable] = useState<string[][]>();
  const [recordFieldsCount, setRecordFieldsCount] = useState<number>();
  const [columnSettings, setColumnSettings] = useState<("" | "firstname" | "lastname" | "name" | "phoneNumber")[]>();
  const [addContactRequests, setAddContactRequests] = useState<AddContactRequest[]>();

  const onContactsParsed = (raw: string, table: string[][], width: number) => {
    setRawTable(raw);
    setSeparatedTable(table);
    setRecordFieldsCount(width);
    goToNext();
  };
  const onTableSetup = (settings: ("" | "firstname" | "lastname" | "name" | "phoneNumber")[]) => {
    setColumnSettings(settings);
    goToNext();
  };

  const onRequestsGenerated = (requests: AddContactRequest[]) => {
    setAddContactRequests(requests);
    goToNext();
  };

  const goBack = () => {
    goToPrevious();
  };
  return (
    <Box maxWidth="1024px" padding="32px">
      <Stepper index={activeStep} orientation="vertical" gap={0} height="180px" marginBottom="32px">
        <Step key={0}>
          <StepIndicator>
            <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
          </StepIndicator>
          <Box flexShrink="0">
            <StepTitle>Import danych z tabeli</StepTitle>
            <StepDescription>Wklej dane z tabeli - przykładowo z excela</StepDescription>
          </Box>
          <StepSeparator />
        </Step>
        <Step key={1}>
          <StepIndicator>
            <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
          </StepIndicator>
          <Box flexShrink="0">
            <StepTitle>Przypisz pola do kolumn</StepTitle>
            <StepDescription>Użyj listy rozwijanej nad kolumnami</StepDescription>
          </Box>
          <StepSeparator />
        </Step>
        <Step key={2}>
          <StepIndicator>
            <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
          </StepIndicator>
          <Box flexShrink="0">
            <StepTitle>Potwierdź przykładowe kontakty</StepTitle>
            <StepDescription>
              Lista zawiera tylko kawałek kontaktów - sprawdź i potwierdź czy dane są w prawidłowych polach
            </StepDescription>
          </Box>
          <StepSeparator />
        </Step>
        <Step key={2}>
          <StepIndicator>
            <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
          </StepIndicator>
          <Box flexShrink="0">
            <StepTitle>Importuj</StepTitle>
            <StepDescription>Zapisywanie kontaktów na serwerze</StepDescription>
          </Box>
          <StepSeparator />
        </Step>
      </Stepper>
      {activeStep === 0 && <RawTableContactsParser rawTableDefault={rawTable} next={onContactsParsed} />}

      {activeStep === 1 && separatedTable !== undefined && recordFieldsCount !== undefined && (
        <SetupContactsTable separatedTable={separatedTable} recordFieldsCount={recordFieldsCount} next={onTableSetup} />
      )}
      {activeStep === 2 &&
        separatedTable !== undefined &&
        recordFieldsCount !== undefined &&
        columnSettings !== undefined && (
          <GenerateAddContactsRequest
            table={separatedTable}
            tableSettings={columnSettings}
            next={onRequestsGenerated}
          />
        )}
      {activeStep === 3 &&
        separatedTable !== undefined &&
        recordFieldsCount !== undefined &&
        columnSettings !== undefined &&
        addContactRequests !== undefined && <SendContactsToServer requests={addContactRequests} />}
      <Button width="100%" colorScheme="blue" marginBottom="8px" onClick={goBack}>
        Cofnij
      </Button>
    </Box>
  );
};
