import { Box, Button, Spinner, Text } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { AddContactRequest } from "../../types/Contact";

export const GenerateAddContactsRequest: FC<{
  table: string[][];
  tableSettings: ("" | "firstname" | "lastname" | "name" | "phoneNumber")[];
  next: (requests: AddContactRequest[]) => void;
}> = ({ table, tableSettings, next }) => {
  const [requests, setRequests] = useState<AddContactRequest[]>();

  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current === true) {
      return;
    }
    mounted.current = true;

    const firstNameIndex = tableSettings.indexOf("firstname");
    const lastNameIndex = tableSettings.indexOf("lastname");
    const nameIndex = tableSettings.indexOf("name");
    const phoneNumberIndex = tableSettings.indexOf("phoneNumber");

    const result: AddContactRequest[] = [];
    for (const line of table) {
      const firstName = line[firstNameIndex];
      const lastName = line[lastNameIndex];
      const name = line[nameIndex];
      const phoneNumber = line[phoneNumberIndex];
      const request: AddContactRequest = {
        firstName,
        lastName,
        name,
        phoneNumber,
        isCompany: false,
      };
      result.push(request);
    }
    setRequests(result);
  }, [table, tableSettings]);

  const handleNext = () => {
    if (requests === undefined || requests.length === 0) {
      return;
    }
    next(requests);
  };

  if (requests === undefined || requests.length === 0) {
    return <Spinner size={"lg"} />;
  }

  return (
    <Box>
      {requests.slice(0, 5).map((x, i) => (
        <Box key={i} bgColor="blue.800" marginTop="8px" marginBottom="8px" padding="16px">
          <Text>Nazwa: {x.name}</Text>
          <Text>Imię: {x.firstName}</Text>
          <Text>Nazwisko: {x.lastName}</Text>
          <Text>Numer telefonu: {x.phoneNumber}</Text>
        </Box>
      ))}
      <Button
        width="100%"
        marginBottom="8px"
        marginTop="24px"
        colorScheme="teal"
        onClick={handleNext}
        isDisabled={requests === undefined || requests.length === 0}
      >
        Potiwerdzam że dane się zgadzają - Importuj
      </Button>
    </Box>
  );
};
