import { Alert, AlertIcon, Box, Button, Textarea } from "@chakra-ui/react";
import { FC, useState } from "react";

export const RawTableContactsParser: FC<{
  rawTableDefault: string;
  next: (rawTable: string, separatedTable: string[][], recordFieldsCount: number) => void;
}> = ({ rawTableDefault, next }) => {
  const [rawTable, setRawTable] = useState<string>(rawTableDefault);
  const [error, setError] = useState<string | undefined>();

  const separate = () => {
    const separator = "\t"; //todo find separator
    let separated = rawTable
      .replace("\r", "")
      .split("\n")
      .map((x) => x.split(separator).map((c) => c.trim()));

    if (separated.length === 0) {
      setError("Tabela jest pusta");
      return;
    }
    const sortedWidth = separated.map((x) => x.length).sort((a, b) => a - b);
    const width = sortedWidth[Math.floor(sortedWidth.length / 2)];
    separated = separated.filter((x) => x.length === width);

    if (width < 2) {
      setError("Muszą być minimum 2 kolumny");
      return;
    }
    next(rawTable, separated, width);
  };

  return (
    <>
      <Box>
        <Textarea
          placeholder="Wklej tabele"
          minHeight="400px"
          resize="none"
          value={rawTable}
          onChange={(x) => setRawTable(x.target.value)}
        />
        {error !== undefined && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        <Button
          marginTop="20px"
          marginBottom="20px"
          width="100%"
          colorScheme="teal"
          onClick={separate}
          isDisabled={rawTable.length === 0 || !rawTable.includes("\t")}
        >
          Dalej
        </Button>
      </Box>
    </>
  );
};
