import { FC, useEffect, useRef, useState } from "react";
import { AddContactRequest } from "../../types/Contact";
import { useContacts } from "../../contexts/ContactsContext";
import { Box, Progress, Text } from "@chakra-ui/react";

export const SendContactsToServer: FC<{ requests: AddContactRequest[] }> = ({ requests }) => {
  const { addContact } = useContacts();
  const [requestsSent, setRequestsSent] = useState<number>(0);
  const [isDone, setIsDone] = useState(false);

  const mounted = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sendNextRequest = (currentIndex: number) => {
    if (isDone) {
      return;
    }
    if (currentIndex >= requests.length) {
      setIsDone(true);
      return;
    }
    const request = requests[currentIndex];
    addContact(request).then(() => {
      const nextIndex = currentIndex + 1;
      setRequestsSent(nextIndex);
      sendNextRequest(nextIndex);
    });
  };

  useEffect(() => {
    if (mounted.current === true) {
      return;
    }
    mounted.current = true;
    sendNextRequest(0);
  }, [sendNextRequest]);

  return (
    <Box marginBottom="24px">
      <Progress colorScheme={isDone ? "green" : undefined} value={Math.round((requestsSent / requests.length) * 100)} />
      <Text>
        {requestsSent}/{requests.length} {`(${Math.round((requestsSent / requests.length) * 100)}%)`}
      </Text>
      {isDone && (
        <Text fontSize="4xl" textAlign="center">
          Koniec importu.
          <br />
          Zaimportowano {requestsSent} kontaktów.
        </Text>
      )}
    </Box>
  );
};
