import { useToast } from "@chakra-ui/react";
import { useAuth } from "../contexts/AuthContext";
import { AddContactRequest, BaseContactDto, DeleteContactRequest, SearchContactResponse } from "../types/Contact";

const localServer = "https://localhost:7281";
const remoteServer = "https://api.zlopek.com";

const useRemoteServerLocally = false;

const getServerAddress = () => {
  if (window.location.hostname !== "localhost") {
    return remoteServer;
  }
  return useRemoteServerLocally ? remoteServer : localServer;
};

export const baseAddress = getServerAddress();

const postRequestData = async <T,>(
  path: string,
  data: {} | undefined = undefined,
  onError: (code: number) => void,
  onSuccess: () => void
): Promise<T> => {
  const response = await fetch(baseAddress + path, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: data === undefined ? undefined : JSON.stringify(data),
  });
  if (response.ok === false) {
    onError(response.status);
    return null as T;
  }
  onSuccess();
  const body = await response.text();
  return JSON.parse(body) as T;
};

const postRequest = async (
  path: string,
  data: {} | undefined = undefined,
  onError: (code: number) => void,
  onSuccess: () => void
) => {
  const response = await fetch(baseAddress + path, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: data === undefined ? undefined : JSON.stringify(data),
  });
  if (response.ok === false) {
    onError(response.status);
    return;
  }
  onSuccess();
};

export const useApi = () => {
  const auth = useAuth();
  const toast = useToast();
  const onError = (code: number) => {
    if (code === 401) {
      auth.setLoggedOut();
    } else {
      toast({
        title: `Wystąpił błąd. Status: ${code}.`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const logout = () => postRequest("/session/forWeb/logout", undefined, onError, auth.setLoggedOut);
  const getMobilePairCode = () =>
    postRequestData<string>("/session/forPhone/code", undefined, onError, auth.setLoggedIn);
  const callPhone = (phoneNumber: string) => postRequest("/call", { phoneNumber }, onError, auth.setLoggedIn);
  const confirm = () => postRequest("/session/forWeb/confirm", undefined, onError, auth.setLoggedIn);
  const isPhoneConnected = () => postRequestData<boolean>("/isPhoneConnected", undefined, onError, auth.setLoggedIn);

  const addContact = (request: AddContactRequest): Promise<BaseContactDto> => {
    return postRequestData<BaseContactDto>("/contacts/add", request, onError, auth.setLoggedIn);
  };
  const searchContacts = (): Promise<SearchContactResponse> => {
    return postRequestData<SearchContactResponse>("/contacts/search", undefined, onError, auth.setLoggedIn);
  };
  const deleteContact = (request: DeleteContactRequest): Promise<void> => {
    return postRequest("/contacts/delete", request, onError, auth.setLoggedIn);
  };

  return {
    logout,
    getMobilePairCode,
    callPhone,
    confirm,
    isPhoneConnected,
    addContact,
    searchContacts,
    deleteContact,
  };
};
