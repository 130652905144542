import { Box, VStack } from "@chakra-ui/react";
import { Contacts } from "./pages/Contacts";
import { GoogleLoginButton } from "./components/GoogleLoginButton";
import { useApi } from "./hooks/useApi";
import { useEffect, useRef } from "react";
import { useAuth } from "./contexts/AuthContext";
import { Menu } from "./components/Menu";
import { Navigate, Outlet, RouterProvider, createBrowserRouter, useLocation } from "react-router-dom";
import { ImportContacts } from "./pages/ImportContacts";
import { Calling } from "./pages/Calling";

const Layout = () => {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.isLoggedIn && location.pathname !== "/login") {
    return <Navigate to={"/login"} />;
  }
  if (auth.isLoggedIn && location.pathname === "/login") {
    return <Navigate to={"/"} />;
  }
  return (
    <Box display="flex" justifyContent="center">
      <VStack width="100%" maxWidth="1920px">
        {auth.isLoggedIn && (
          <Box width="100%" marginTop="16px" marginBottom="32px">
            <Menu />
          </Box>
        )}

        <Box width="100%" display="flex" justifyContent="center">
          <Outlet />
        </Box>
      </VStack>
    </Box>
  );
};
const Login = () => (
  <Box display="flex" justifyContent="center">
    <GoogleLoginButton />
  </Box>
);
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Contacts />,
        errorElement: <>Error</>,
      },
      {
        path: "/login",
        element: <Login />,
        errorElement: <>Error</>,
      },
      {
        path: "/import",
        element: <ImportContacts />,
        errorElement: <>Error</>,
      },
      {
        path: "/calling",
        element: <Calling />,
        errorElement: <>Error</>,
      },
    ],
  },
]);

export const App = () => {
  const api = useApi();
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current === true) {
      return;
    }
    mounted.current = true;
    api.confirm();
  });

  return <RouterProvider router={router} />;
};
