import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useApi } from "../hooks/useApi";
import { useAuth } from "./AuthContext";

const signalRConnection: HubConnection = new HubConnectionBuilder()
  .withUrl("https://localhost:7281/callingHub")
  .withAutomaticReconnect()
  .build();

export interface SignalRContextType {
  phoneConnected: boolean;
}

export const SignalRContext = createContext<SignalRContextType>(null!);

export const SignalRProvider = ({ children }: { children: React.ReactNode }) => {
  const api = useApi();
  const auth = useAuth();
  const mounted = useRef(false);
  const [phoneConnected, setPhoneConnected] = useState(false);

  const on = (methodName: string, newMethod: (...args: any[]) => any) => {
    signalRConnection.on(methodName, newMethod);
  };

  useEffect(() => {
    if (mounted.current === true) {
      return;
    }
    mounted.current = true;
    api.isPhoneConnected().then((x) => setPhoneConnected(x));

    on("PhoneConnected", () => setPhoneConnected(true));
    on("PhoneDisconnected", () => setPhoneConnected(false));
  }, [api]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      if (signalRConnection.state === HubConnectionState.Disconnected) {
        signalRConnection.start();
      }
    } else {
      signalRConnection.stop();
    }
  }, [auth.isLoggedIn]);

  const value: SignalRContextType = {
    phoneConnected,
  };

  return <SignalRContext.Provider value={value}>{children}</SignalRContext.Provider>;
};

export const useSignalR = () => useContext(SignalRContext);
